<template>
  <PageWithHeader>
    <template #title>
      <t path="tradingcentral_2"></t>
    </template>
    <div class="p-16">
      <div class="df-middle">
        <Pic src="trade/technical" width="64" height="64" />
        <div class="ml-16">
          <t as="p" path="tradingcentral_3" class="f-bold">Technical view</t>
          <t as="p" path="tradingcentral_4" class="f-sm mt-8">Drive confident decisions with simple, actionable trading plans.</t>
        </div>
      </div>
      <Line class="mt-16" />
      <Pic exact src="/img/prime/technicals.jpg" height="400" class="mt-16" style="width: 100%;" />
      <!-- <t as="p" path="tradingcentral_5" class="mt-16" style="line-height: 24px;"></t> -->
      <t as="div" path="tradingcentral_5" multiline #="{td}" custom class="mt-16" >
        <p v-for="(p, index) in td" :key="index" style="line-height: 24px;">{{p}}</p>
      </t>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'TheTechnicalAnalytics',
  components: {
    Pic,
    Line,
    PageWithHeader,
  },
})
</script>

<style lang="scss">

</style>
